.image-flex {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
}

.card-flex {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4px rgb(212, 209, 209);
    padding: 1rem;
}

.image-placeholder {
    opacity: 0.4;
}
.p-button{

    background   :  #1cb4b5;

    border       : 1px solid #1cb4b5;

}
